import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ModalType {
  Success,
  Fail,
  SuccessWithdraw
}

const initState = {
  type: null
} as {
  type: null | ModalType
};

const ModalSlice = createSlice({
  name: 'modal',
  initialState: initState,
  reducers: {
    setModalType(state, { payload }: PayloadAction<ModalType | null>) {
      state.type = payload;
    },
  },
});

export const { setModalType } = ModalSlice.actions;

export default ModalSlice.reducer;
