import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { ModalType } from '../../store/reducers/Modal';
import { getModalTypeSelector } from '../../store/reducers/selectors';

import Fail from './Fail';
// import Success from './Success';
import Success from './Success';

import "./styles.css"


export default function Modal() {
  const type = useSelector(getModalTypeSelector);

  const getModal = useCallback(()=>{

    switch (type) {
      case ModalType.Success:
        return <Success title="Congratulations, you have successfully topped up your balance. Now you can return to the app."/>
      case ModalType.SuccessWithdraw:
        return <Success title="Congratulations, you have successfully requested a withdrawal. Now you can return to the app."/>
      case ModalType.Fail:
        
        return <Fail />

    
      default:
        return null
    }

  }, [type])

  return getModal()
}