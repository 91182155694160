import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'react-credit-cards';
import Payment from 'payment';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ModalType, setModalType } from '../../store/reducers/Modal';

import Input from '../../components/Input';
import Header from '../../components/header';

import { formatCreditCardNumber, formatCVC, formatExpirationDate } from './utils';

import 'react-credit-cards/es/styles-compiled.css';
import './styles/styles.css';


const App = ({ title, subTitle, spec})=>{
  const {theme='white',jwt=''} = useParams();

  const refForm = useRef(null);

  const dispatch = useDispatch();

  const [focused,setFocused] = useState('');

  const [isLoading,setSate] = useState(false)

  const [debugModeValue,setInformationStatus] = useState(0)
  
  const [amount,setAmount] = useState({
    value:'',
    error:''
  })

  const [cardNumber, setCardNumber] = useState({
    value:'',
    error:''
  })
  const [cardName, setCardName] = useState({
    value:'',
    error:''
  })
  const [expiry, setExpiry] = useState({
    value:'',
    error:''
  })
  const [cvc, setCvc] = useState({
    value:'',
    error:''
  })

  const handleCallback = ({ issuer }, isValid) => {

  };

  const handleInputFocus = (type)=>(e) => {


    // if (type === 'amount'){
    //   setAmount({
    //     value:1,
    //     error:''
    //   })
    // }
    
    setFocused(e.target.name)
  };

  const handeInputBlure = (type)=>(e)=>{

    handleInputChange(type,false)(e);
  }

  const handleInputChange = useCallback((type,skipError = true)=>({target})=>{
    const {value} = target
    let error = ''
    switch (type) {
      case 'cardNumber':

        let valid = Payment.fns.validateCardNumber(value);

        if (!valid) {
          error = 'invalid card number'
        }
        

        setCardNumber({
          value: formatCreditCardNumber(value),
          error: skipError ? '' : error
        })
        
        break;
      case 'cardName':
        error = value.includes(' ') ? '' : 'Please add last name'
        setCardName({
          value: target.value,
          error: skipError ? '' : error
        })
        
        break;
      case 'expiry':
        let newValue = value.toString().replaceAll(/[^\d]/g, "").replace('/','').substr(0,4)
        if (newValue.length === 4){
          setExpiry({
            value: formatExpirationDate(newValue),
            error
          })
        } else {
          setExpiry({
            value: formatExpirationDate(newValue),
            error: skipError ? '' : 'Invalid Format' 
          })
        }
        
        break;
      case 'cvc':
        setCvc({
          value: formatCVC(value.substr(0,3)),
          error: skipError? '' : formatCVC(value.substr(0, 3)).length < 3 ? "Invalid code" : ''
        })
        
        break;
      case 'amount':
        let newV = value.replaceAll(/[^\d]/g, "")
        setAmount({
          value: newV,
          error: +newV > 0 ? '' :'Enter positive amount'
        })
        
        break;
    
      default:
        break;
    }
  },[])

  useEffect(()=>{
     const body = document.getElementsByTagName('body')[0];
     
     if(body){
      body.className = '';
      body.className = theme;

     }

  },[theme])

  // 

  const handleSetDebugMode = useCallback(()=>{
    setInformationStatus(debugModeValue+1)
  }, [debugModeValue])
    
  const onSubmit = useCallback(async (e)=>{
    e.preventDefault();
    setSate(true)
    try {
      const response = await fetch('https://bots31415.twl.ae:16990/account/TopUpWallet', {
        method: 'POST',
        // mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify({ amount: +amount.value * spec })
      });

      if (response.status === 200) {
        setSate(false)
        if (spec < 0){
          setSate(false)
          dispatch(setModalType(ModalType.SuccessWithdraw))
        }else {
          setSate(false)
          dispatch(setModalType(ModalType.Success))
        }
      } else {
        setSate(false)
        dispatch(setModalType(ModalType.Fail))
      }
    } catch (error) {
      dispatch(setModalType(ModalType.Fail))
      setSate(false)
    }
  }, [jwt, dispatch, amount, spec])

  const isAvailable = useMemo(()=>{
    const states = [amount,
    cardNumber,
    cardName,
    expiry,
    cvc]

    return states.filter(s =>  !s.error.length && s.value.length).length === 5

  }, [amount,
    cardNumber,
    cardName,
    expiry,
    cvc])



  return (
    <div key="Payment" className={"static " + theme}>

      {debugModeValue > 5 && (
        <div>
          <div style={{ wordBreak: "break-all" }}>location:  {window.location.href}</div>
          <br/>
          <div>jwt:   <span style={{ wordBreak: "break-all"}}>{jwt}</span></div>
          </div>
      )}

      <Header title={title} subTitle={subTitle} setInformationStatus={handleSetDebugMode}/>

      <div className="App-payment">
        <Card
          number={cardNumber.value}
          name={cardName.value}
          expiry={expiry.value}
          cvc={cvc.value}
          focused={focused}
          callback={handleCallback}
        />
        <form ref={refForm} >
          <Input 
            placeholder="Card Number"
            value={cardNumber.value}
            onChange={handleInputChange('cardNumber')}
            onFocus={handleInputFocus(('cardNumber'))}
            onBlure={handeInputBlure(('cardNumber'))}
            name="number"
            errorText={cardNumber.error}
            // pattern="[\d| ]{16,22}"
          />
          <Input 
            placeholder="Name"
            value={cardName.value}
            onChange={handleInputChange('cardName')}
            onFocus={handleInputFocus('cardName')}
            onBlure={handeInputBlure('cardName')}
            name="expiry"
            errorText={cardName.error}
           
          />
         
          <div className="row">
            <div className="col-6">
              <Input
                name="expiry"
                // pattern="\d\d/\d\d"
                placeholder="MM/YY"
                value={expiry.value}
                onChange={handleInputChange('expiry')}
                onFocus={handleInputFocus('expiry')}
                onBlure={handeInputBlure('expiry')}
                errorText={expiry.error}
                iconName="Calendar"
              />
            </div>
            <div className="col-6">
              <Input
                name="cvc"
                // pattern="\d{3,4}"
                placeholder="CVC"
                value={cvc.value}
                onChange={handleInputChange('cvc')}
                onFocus={handleInputFocus('cvc')}
                onBlure={handeInputBlure('cvc')}
                errorText={cvc.error}
              />
            </div>
          </div>

            <Input
              placeholder="Cash"
              value={amount.value}
              onChange={handleInputChange('amount')}
              onFocus={handleInputFocus('amount')}
              errorText={amount.error}
              iconName="AED"
            />


          <div className="form-actions">
            <div className={`button example ${!isAvailable ? 'opacity' : ''}`} onClick={(isAvailable && !isLoading) ? onSubmit : undefined}>
                Confirm
            </div>
          </div>
        </form>

      </div>
    </div>
  )
}

export default App

