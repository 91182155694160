import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import './App.css';
import CloseApp from './pages/closePage';
import NotFound from './pages/notFound';
import TopUpTawasalWallet from './pages/topUp/TopUpTawasalWallet';

function App() {
  return (
    <Routes>
      <Route path="/top-up/:theme/:jwt" element={<TopUpTawasalWallet 
      title="Top up Tawasal Wallet"
       subTitle="Please enter your payment details and top up transaction amount"
        spec={1}
       />}
       />

      <Route path="/withdraw/:theme/:jwt" element={<TopUpTawasalWallet
       title="Withdraw" 
       subTitle="Please enter your payment details and withdraw transaction amount"
        spec={-1} />} />


      <Route path='/404' element={<NotFound />} />
      <Route path='/close' element={<CloseApp />} />
      <Route
        path="*"
        element={<Navigate to="/404" replace />}
      />
    </Routes>
  );
}

export default App;
