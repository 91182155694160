import React from 'react'

import "./styles.css"

export default function NotFound() {
  return (
    <div className="not-found">
      {window.location.href}
    </div>
  )
}