import React from 'react'

import './styles.css'

type Props = {
  name:string;
  placeholder:string;
  onChange:(e:any)=>void
  onFocus:(e:any)=>void
  value:string;
  pattern?:string;
  errorText?:string
  iconName?:string
  onBlure?:(e:any)=>void

}

const AED = () => <svg width="30" height="12" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.53125 1.63281L1.76562 12H0.226562L4.5625 0.625H5.55469L5.53125 1.63281ZM8.6875 12L4.91406 1.63281L4.89062 0.625H5.88281L10.2344 12H8.6875ZM8.49219 7.78906V9.02344H2.10156V7.78906H8.49219ZM19.1344 10.7734V12H13.1109V10.7734H19.1344ZM13.4156 0.625V12H11.9078V0.625H13.4156ZM18.3375 5.51562V6.74219H13.1109V5.51562H18.3375ZM19.0562 0.625V1.85938H13.1109V0.625H19.0562ZM24.2297 12H21.8547L21.8703 10.7734H24.2297C25.0422 10.7734 25.7193 10.6042 26.2609 10.2656C26.8026 9.92188 27.2089 9.44271 27.4797 8.82812C27.7557 8.20833 27.8937 7.48438 27.8937 6.65625V5.96094C27.8937 5.3099 27.8156 4.73177 27.6594 4.22656C27.5031 3.71615 27.274 3.28646 26.9719 2.9375C26.6698 2.58333 26.3 2.3151 25.8625 2.13281C25.4302 1.95052 24.9328 1.85938 24.3703 1.85938H21.8078V0.625H24.3703C25.1151 0.625 25.7948 0.75 26.4094 1C27.024 1.24479 27.5526 1.60156 27.9953 2.07031C28.4432 2.53385 28.787 3.09635 29.0266 3.75781C29.2661 4.41406 29.3859 5.15365 29.3859 5.97656V6.65625C29.3859 7.47917 29.2661 8.22135 29.0266 8.88281C28.787 9.53906 28.4406 10.099 27.9875 10.5625C27.5396 11.026 26.9979 11.3828 26.3625 11.6328C25.7323 11.8776 25.0214 12 24.2297 12ZM22.6594 0.625V12H21.1516V0.625H22.6594Z" fill="black" fillOpacity="0.87" />
</svg>

const Calendar = () => <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6 9H4V11H6V9ZM10 9H8V11H10V9ZM14 9H12V11H14V9ZM16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18Z" fill="black" fillOpacity="0.6" />
</svg>


export default function Input({ onChange, name, value, placeholder, onFocus, errorText = '', iconName, onBlure=undefined}: Props) {

  const getIcon = () => {

    if (iconName === "AED"){
      return (
        <div className='input-icon -position'>
          <AED />
        </div>)
    }else {
      return (
        <div className='input-icon'>
          <Calendar />
      </div>
      )
    }
  }

  return (<>
    <div className={errorText ? '-error group' : 'group'}>
      <input type="text" name={name} value={value} required onChange={onChange} onFocus={onFocus} onBlur={onBlure}/>
      <label>{placeholder}</label>
      {errorText && <span>{errorText}</span>}

      {iconName ? 
        getIcon()
      : '' }
    </div>
  </>
  )
}