import React from 'react'
import { Link, useNavigate } from 'react-router-dom';


export default function Success({title}:any) {
  const navigsate = useNavigate();

  const onClose = () => {
    window.location.replace("https://wallet.twl.ae/#success");
    navigsate('/close#success');
  }
  
  return (
    <div className="f-modal-alert" >

    <div className="information-block">
      <div className="title">
          {title}
          
      </div>
    </div>

        <div className="f-modal-icon f-modal-success animate">
          <span className="f-modal-line f-modal-tip animateSuccessTip"></span>
          <span className="f-modal-line f-modal-long animateSuccessLong"></span>
          <div className="f-modal-placeholder"></div>
          <div className="f-modal-fix"></div>
        </div>



      <div className="button-go-app" onClick={onClose}>
          Return to app
        </div>
    </div>
  )
}