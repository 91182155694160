import React from 'react'
import { useDispatch } from 'react-redux'
import { setModalType } from '../../store/reducers/Modal';


export default function Fail() {

  const dispatch = useDispatch();

  const onClose = ()=>{
    dispatch(setModalType(null))

  }

  return (
    <div className="f-modal-alert" onClick={onClose}>
        <div className="f-modal-icon f-modal-error animate">
          <span className="f-modal-x-mark">
            <span className="f-modal-line f-modal-left animateXLeft"></span>
            <span className="f-modal-line f-modal-right animateXRight"></span>
          </span>
          <div className="f-modal-placeholder"></div>
          <div className="f-modal-fix"></div>
        </div>

    </div>
  )
}