import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ModalType, setModalType } from '../../store/reducers/Modal';

type Props = {}

const CloseApp = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setModalType(ModalType.Success))
  },[])
  return (
    <div>CloseApp</div>
  )
}

export default CloseApp